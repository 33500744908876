import { Link } from "../Link"
import isPropValid from "@emotion/is-prop-valid"
import styled, { StyledComponent } from "@emotion/styled"
import tw from "twin.macro"

import { IButtonAProps, IButtonLinkProps, IButtonProps } from "./Button.d"
import { css } from "@emotion/react"

/**
 * Styled <button> component with various styling options.
 *
 * @component
 * @param {IButtonProps} props
 * @param {boolean} props.primary - Red pill button
 * @param {boolean} props.secondary - Dark gray, outlined pill button
 * @param {boolean} props.secondaryBgLight - Dark gray, outlined pill button with light bg
 * @param {boolean} props.secondaryLight - white, outlined pill button
 * @param {boolean} props.negative - Red outlined pill button
 * @param {boolean} props.invisible - Pill button with no background
 * @param {boolean} props.action - Dark gray, outlined, rounded button
 * @param {boolean} props.lightGray - Light gray, rounded button
 * @param {boolean} props.disabled - Disables the button and sets opacity to 50%
 *
 * @returns {StyledComponent} - <button />
 *
 * @todo Change Link import to our Link component in /atoms
 */

// Reference: https://emotion.sh/docs/styled#customizing-prop-forwarding
// Stu has modified Button to allow "non-valid" html attribute "analytics-id"
export const Button: StyledComponent<IButtonProps> = styled("button", {
  shouldForwardProp: prop =>
    isPropValid(prop) || prop === "analytics-id" || prop === "state",
})(
  ({
    primary,
    secondary,
    negative,
    invisible,
    action,
    disabled,
    secondaryLight,
    secondaryBgLight,
    lightGray,
    block,
    rounded,
    title,
  }) => [
    /**
     * Default Button styles
     */
    tw`text-sm text-center font-semibold inline-block uppercase px-4 py-3 overflow-hidden transition ease-out duration-300 relative z-10 rounded-full shadow-2`,
    tw`md:(px-8)`,
    rounded && tw`rounded`,
    // Focus
    tw`focus-visible:outline-gray-50`,
    // Hover pseudo element
    css`
      &:after {
        content: "";
        z-index: -1;
        ${tw`block w-full h-full absolute top-0 -left-1 rounded-full transform -translate-x-full transition ease-out duration-300`}
        ${rounded && tw`rounded`}
      }

      &:hover:after,
      &:focus:after {
        ${tw`md:(translate-x-0 left-0)`}
      }
    `,

    /**
     * Block
     */
    block && tw`block`,

    /**
     * Primary button
     */
    primary && tw`text-white shadow-2 z-10 py-3.5`,
    primary &&
      css`
        &:before {
          content: "";
          z-index: -1;
          ${tw`bg-red-400 block w-full h-full absolute top-0 left-0`};
        }

        &:after {
          ${tw`bg-red-600`}
        }

        &:active {
          &:after {
            ${tw`bg-red-400`}
          }
        }
      `,

    /**
     * Secondary button
     */
    secondary && tw`text-black border-black border-2 shadow-2`,
    secondary &&
      css`
        &:after {
          ${tw`bg-red-400`}
        }

        &:active {
          &:after {
            ${tw`bg-white`}
          }
        }
      `,
    // Hover
    secondary && tw`md:(hover:(text-white))`,
    // Focus
    secondary && tw`md:(focus:(text-white))`,
    // Active
    secondary && tw`md:(active:(text-black))`,

    /**
     * SecondaryBgLight button
     */

    secondaryBgLight &&
      tw`text-black border-black border-2 shadow-2 bg-white bg-opacity-80`,
    secondaryBgLight &&
      css`
        &:after {
          ${tw`bg-red-400`}
        }

        &:active {
          &:after {
            ${tw`bg-white`}
          }
        }
      `,
    // Hover
    secondaryBgLight && tw`md:(hover:(text-white))`,
    // Focus
    secondaryBgLight && tw`md:(focus:(text-white))`,
    // Active
    secondaryBgLight && tw`md:(active:(text-black))`,

    /**
     * Secondary Light button
     */
    secondaryLight && tw`text-white leading-6`,
    secondaryLight &&
      css`
        box-shadow: inset 0px 0px 0px 2px white;
      `,
    secondaryLight &&
      css`
        &:after {
          ${tw`bg-white`}
        }

        &:active {
          &:after {
            ${tw`bg-gray-50`}
          }
        }
      `,
    // Hover
    secondaryLight && tw`md:(hover:(text-red-400))`,
    // Focus
    secondaryLight && tw`md:(focus:(text-red-400))`,
    // Active
    secondaryLight && tw`md:(active:(text-red-400))`,

    /**
     * Negative button
     */
    negative && tw`text-red-400 border-red-400 bg-white border-2 shadow-2`,
    negative &&
      // Hover class removes white edges
      css`
        &:hover {
          ${tw`bg-red-400 transition-[background-color] duration-300`}
        }
        &:after {
          ${tw`bg-red-400`}
        }

        &:active {
          &:after {
            ${tw`bg-white`}
          }
        }
      `,
    // Hover
    negative && tw`hover:(text-white)`,
    // Focus
    negative && tw`focus:(text-white)`,
    // Active
    negative && tw`active:(text-red-400)`,

    /**
     * Invisible button
     */
    invisible && tw`text-black`,
    invisible &&
      css`
        &:after {
          ${tw`bg-red-400`}
        }
        &:active {
          &:after {
            ${tw`bg-white`}
          }
        }
      `,
    // Hover
    invisible && tw`hover:(text-white shadow-3)`,
    // Focus
    invisible && tw`focus:(text-white shadow-3)`,
    // Active
    invisible && tw`active:(text-black shadow-2)`,

    /**
     * Action button
     */
    action && tw`normal-case rounded text-black border-black border-2`,
    // Hover
    action && tw`hover:(bg-gray-200)`,
    // Focus
    action && tw`focus:(bg-gray-200)`,
    // Active
    action && tw`active:(bg-white)`,

    /**
     * Light Gray button
     */
    lightGray && tw`text-black bg-gray-50`,
    // Hover
    lightGray && tw`hover:(bg-black text-white)`,
    // Focus
    lightGray && tw`focus:(bg-black text-white)`,
    // Active
    lightGray && tw`active:(bg-black text-white)`,

    /**
     * Disabled button
     */
    disabled && tw`opacity-50 pointer-events-none`,
    /* Optional descriptive title for button */
    title && title,
  ],
)

/**
 * Styled <Link> element to look like Button
 *
 * @component
 * @extends Button
 * @extends Link
 *
 * @returns {StyledComponent} - <Link />
 */
export const ButtonLink: StyledComponent<IButtonLinkProps> =
  Button.withComponent(Link)

/**
 * Styled <a> element to look like Button
 *
 * @component
 * @extends Button
 * @extends a
 *
 * @returns {StyledComponent} - <a></a>
 */
export const ButtonA: StyledComponent<IButtonAProps> = Button.withComponent("a")

export default Button
